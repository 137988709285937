import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_elfaiotbbx/layout/core'
import { WorkingTimeList } from '../../../_elfaiotbbx/partials/widgets/lists/WorkingTimeList'
import { Roles } from './Roles'





const systemBreadCrumbs: Array<PageLink> = [
    {
        title: 'System',
        path: '/dev/system/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const System = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='roles'
                    element={
                        <>
                            <PageTitle breadcrumbs={systemBreadCrumbs}>Roller</PageTitle>
                            <Roles />
                        </>
                    }
                />
                 
            </Route>
            
        </Routes>
    )
}

export default System
