/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { BLACKBOXES_GET_DEVICELIST_WITH_PAGEABLE, BLACKBOXES_ONE_URL } from '../../../../urls'
import { blackboxIdContext } from '../../../../app/modules/profile/components/contexts/BlackboxIdContext'
import { useAuth } from '../../../../app/modules/auth'
import { Link } from 'react-router-dom'

type Props = {
  className: string
}

const WorkingTimeList: React.FC = () => {

  const className = "card-xl-stretch mb-5 mb-xl-8"
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState()
  const [deviceList, setDeviceList] = useState([])
  const currentBlackBoxId = useContext(blackboxIdContext);
  const { currentUser, logout } = useAuth()
  const [canioList, setCanioList] = useState([])
  const [weightList, setWeightList] = useState([])
  const token = currentUser?.token
  const [anyChoosen, setAnyChoosen] = useState(false)
  const [blackboxDetails, setBlackboxDetails] = useState([])


  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
  }



  async function getBlackboxDetails() {

    const response = await fetch(BLACKBOXES_ONE_URL + `?id=${currentBlackBoxId[0]}`, {
      method: "GET",
      headers: createHeader(),
    })

      .then(
        res => res.json()
      )
      .then(
        res => {
          if (res.status == 401) { logout() }
          setBlackboxDetails(res["data"])



        }
      )
  }
  async function getDeviceList() {

    //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
    const response = await fetch(BLACKBOXES_GET_DEVICELIST_WITH_PAGEABLE + `?blackboxId=${currentBlackBoxId[0]}&page=${pageNumber}&size=${pageSize}`, {
      method: "GET",
      headers: createHeader()
    })

      .then(
        res => res.json()
      )
      .then(
        res => {
          console.log(res)
          setPageNumber(res["pageable"]["pageNumber"])
          setPageSize(res["pageable"]["pageSize"])
          setTotalElements(res["totalElements"])
          setTotalPages(res["totalPages"])
          setDeviceList(res)
          setCanioList(res["content"].filter(device => device["deviceName"] === "4"))
          setWeightList(res["content"].filter(device => device["deviceName"] === "1" || device["deviceName"] === "2"));


        }

      )

  }
  useEffect(() => {
    getDeviceList()
    getBlackboxDetails()
  }, [])
  return (
    <>
      <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
        {blackboxDetails["description"]}
      </a>
      <div className='card card-xl-stretch mb-5 mb-xl-8'>
        {/* begin::Header */}

        <div className='card-header align-items-center border-0 mt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold text-dark'>Cihaz Kullanım Hesaplama Sistemleri</span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            {anyChoosen === true ?


              <button
                type='button'
                //className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                className='btn btn-icon btn-light btn-sm border-0'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
              </button>
              : <></>}
            <Dropdown1 />
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-3'>



          {(canioList.length != 0 && Array.isArray(canioList)) &&
            canioList.map((device: any) => {
              return (
                <>
                  <div className='d-flex align-items-sm-center mb-7'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-60px symbol-2by3 me-4'>
                      <div
                        className='symbol-label'
                        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/Canio.jpeg')})` }}
                      ></div>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Content */}
                    <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
                      {/* begin::Title */}
                      <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                        <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                          {device["description"]}
                        </a>
                        <span className='text-muted fw-semibold d-block pt-1'>Kumanda kullanım, Rulman ve kontaktör kullanım süreleri ...</span>
                      </div>
                      {/* end::Title */}
                      {/* begin::Section */}
                      <div className='d-flex align-items-center'>
                        <div className='me-6'>


                        </div>
                        <Link 
                         to={`/crafted/reparation/availablebalance`}
                         state={{ uid: device.uid, deviceDescription:device["description"],indicatorList:weightList }}
                        className='btn btn-icon btn-light btn-sm border-0'>
                          <KTIcon iconName='arrow-right' className='fs-2 text-primary' />
                        </Link>
                      </div>
                      {/* end::Section */}
                    </div>
                    {/* end::Content */}
                  </div></>)
            })}


        </div>
        {/* end::Body */}
      </div>
      <div className='card card-xl-stretch mb-5 mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header align-items-center border-0 mt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold text-dark'>Yük Kontrol Sistemleri</span>
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            {anyChoosen === true ?


              <button
                type='button'
                //className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                className='btn btn-icon btn-light btn-sm border-0'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
              </button>
              : <></>}
            <Dropdown1 />
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-3'>



          {(weightList.length != 0 && Array.isArray(weightList)) &&
            weightList.map((device: any) => {
              return (
                <>
                  <div className='d-flex align-items-sm-center mb-7'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-60px symbol-2by3 me-4'>
                      <div
                        className='symbol-label'
                        style={{ backgroundImage: `url(${toAbsoluteUrl(device["deviceName"] === "1" ? '/media/avatars/dIndicator.jpg' : '/media/avatars/indicator.jpg')})` }}
                      ></div>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Content */}
                    <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
                      {/* begin::Title */}
                      <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                        <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                          {device["description"]}
                        </a>
                        <span className='text-muted fw-semibold d-block pt-1'>Ağırlık aşımı, Toplam taşınan yük</span>
                      </div>
                      {/* end::Title */}
                      {/* begin::Section */}
                      <div className='d-flex align-items-center'>
                        <div className='me-6'>


                        </div>
                        <Link 
                         to={`/crafted/reparation/indicatorreparation`}
                         state={{ uid: device.uid }}
                        className='btn btn-icon btn-light btn-sm border-0'>
                          <KTIcon iconName='arrow-right' className='fs-2 text-primary' />
                        </Link>
                      </div>
                      {/* end::Section */}
                    </div>
                    {/* end::Content */}
                  </div></>)
            })}


        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export { WorkingTimeList }
