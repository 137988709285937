/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import {KTIcon} from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import { BLACKBOXES_GET_LIST_SIZE_URL, GET_INDICATOR_WEIGHT_BY_BLACKBOXID } from '../../../../urls'
import { blackboxIdContext } from '../../../../app/modules/profile/components/contexts/BlackboxIdContext'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
}

const SumLoad: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
}) => {
  
  const {currentUser, logout} = useAuth()
  const token = currentUser?.token
  const [blackboxNumber, setBlackboxNumber] = useState(0)
  const currentBlackBoxId = useContext(blackboxIdContext);
  const [totalLoad, setTotalLoad] = useState(0)
  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
}

  useEffect(() => {
    getIndicatorWeight()
  },[]) 

  async function getIndicatorWeight() {

    //const response = await fetch(BLACKBOXES_DEVICE_LIST_URL+`?blackboxId=${blackboxDetails["serialNumber"]}`, {
   
    const response = await fetch(GET_INDICATOR_WEIGHT_BY_BLACKBOXID + `?blackboxId=${currentUser?.id}&role=${currentUser?.role}`, {
      method: "GET",
      headers: createHeader()
    })

      if(response.status === 401){
        logout()
        return;
      }
      const responseData = await response.json()
 
      setTotalLoad(responseData)
  }   
  function isNumber(value) {
    return typeof value === 'number';
  }

  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body'>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />

        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>Toplam Taşınan Yük : 
        {isNumber(totalLoad) ? totalLoad.toFixed(2) + " kg": "bilinmiyor"}</div>

        <div className={`fw-semibold text-${descriptionColor}`}>Sisteme tanımlı tüm yük indicatörlerin elde edilen veri</div>
      </div>
    </a>
  )
}

export {SumLoad}
