/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

import { useContext } from 'react'
import { blackboxIdContext } from '../../../../app/modules/profile/components/contexts/BlackboxIdContext'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const currentBlackBoxId = useContext(blackboxIdContext);
  const { currentUser } = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />

      {/* <AsideMenuItem to='/builder' icon='switch' title='Layout Builder' /> */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Karakutu</span>
        </div>
      </div>
      <AsideMenuItemWithSub to='/crafted/pages' title='Karakutu' icon='gift'>
        <AsideMenuItem to='/crafted/pages/wizards/blackboxlist' title='Listele' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/wizards/AddBlackbox' title='Karakutu Ekle' hasBullet={true} />
        {currentBlackBoxId[0] != "none" &&
          <>
            <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
              <AsideMenuItem to='/crafted/pages/profile/overview' title='Genel Bakış' hasBullet={true} />
              <AsideMenuItem to='/crafted/pages/profile/documents' title='Raporlar' hasBullet={true} />
              <AsideMenuItem to='/crafted/pages/profile/modbus' title='Modbus' hasBullet={true} />
              <AsideMenuItem to='/crafted/pages/profile/warnings' title='Uyarılar' hasBullet={true} />
              {/* <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          /> */}
              <AsideMenuItem
                to='/crafted/pages/profile/livegraphics'
                title='Canlı Grafik'
                hasBullet={true}
              />
              <AsideMenuItem to='/crafted/pages/profile/devicelist' title='Cihaz Listesi' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub to='/crafted/reparation' title='Bakım' hasBullet={true}>
              <AsideMenuItem to='/crafted/reparation/addproduct' title='Cihaz Ekle' hasBullet={true} />
              <AsideMenuItem to='/crafted/reparation/listproduct' title='Cihaz Listele' hasBullet={true} />
              <AsideMenuItem to='/crafted/reparation/overview' title='Genel Bakış' hasBullet={true} />
              <AsideMenuItem to='/crafted/reparation/systemlog' title='Sistem Logları' hasBullet={true} />
            </AsideMenuItemWithSub>
            {/* <AsideMenuItem to='/crafted/reparation/log' title='Cihaz Kayıt' hasBullet={true} /> */}
          </>
        }
        {/* <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub> */}
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' icon='profile-circle'>
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub to='/error' title='Errors' icon='cross-circle'>
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' icon='element-plus'>
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <AsideMenuItemWithSub to='/apps/chat' title='Chat' icon='message-text-2'>
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem to='/apps/user-management/users' icon='shield-tick' title='User management' /> */}
      <div className='menu-item'>
        <div className='menu-content'>
          {/* <div className='separator mx-1 my-4'></div> */}
        </div>
      </div>


      {
        currentUser?.role === "1" &&
        <AsideMenuItemWithSub to='/crafted/widgets/' title='Elfatek' icon='gift'>


          <AsideMenuItemWithSub to='/crafted/widgets/' title='Ekle' hasBullet={true}>

            <AsideMenuItem to='/crafted/widgets/addBlackbox' title='Karakutu' hasBullet={true} />
            <AsideMenuItem to='/crafted/widgets/addCompany' title='Firma' hasBullet={true} />
            <AsideMenuItem to='/crafted/widgets/addDistributor' title='Distributor' hasBullet={true} />

          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to='/crafted/widgets/' title='Listele' hasBullet={true}>

            <AsideMenuItem to='/crafted/widgets/blackboxlistElfatek' title='Karakutu' hasBullet={true} />
            <AsideMenuItem to='/crafted/widgets/companylistelfatek' title='Firma' hasBullet={true} />
            <AsideMenuItem to='/crafted/widgets/distributorlistelfatek' title='Distrubutor' hasBullet={true} />
          </AsideMenuItemWithSub>


        </AsideMenuItemWithSub>
      }
      {
        currentUser?.role === "2" &&
        <AsideMenuItemWithSub to='/crafted/widgets/' title='Firma' icon='gift'>
          <AsideMenuItem to='/crafted/widgets/addcompanybydistributor' title='Ekle' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/companylistditributor' title='Listele' hasBullet={true} />
        </AsideMenuItemWithSub>
      }

      {/* <AsideMenuItemWithSub to='/crafted/accounts' title='Hesap' icon='profile-circle'>
        <AsideMenuItem to='/crafted/account/overview' title='Genel Bakış' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Ayarlar' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      {
        currentUser?.role === "1" &&
        <AsideMenuItemWithSub to='/crafted/widgets/' title='System' icon='gift'>

          
            <AsideMenuItem to='/crafted/widgets/roles/' title='Karakutu' hasBullet={true} />
          



        </AsideMenuItemWithSub>
      }

      <a href='http://185.86.80.124/remoteauth' title='Ekle' className='btn btn-primary mt-10' style={{}} >
        Gimas
      </a>

    </>
  )
}
