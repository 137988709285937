/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { KTIcon } from '../../../helpers'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { BLACKBOXES_GET_5_ERROR_URL } from '../../../../urls'
import { blackboxIdContext } from '../../../../app/modules/profile/components/contexts/BlackboxIdContext'
import { useAuth } from '../../../../app/modules/auth'

type Props = {
  className: string
}

const Blackbox5Error: React.FC<Props> = ({ className }) => {
  const currentBlackBoxId = useContext(blackboxIdContext);
  const [errorList, setErrorList] = useState([])
  const [loading, setLoading] = useState(false)

  const { currentUser } = useAuth()
  const token = currentUser?.token


  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
  }


  useEffect(() => {
    getTemp5Days(createHeader())
    setTimeout(function () { setLoading(true) }, 3000)
    
  }, [])

  async function getTemp5Days(headers:Headers) {
    
    const response = await fetch(BLACKBOXES_GET_5_ERROR_URL + `?blackboxId=${currentBlackBoxId[0]}`, {
      method: "GET",
      headers:headers,

    })
      .then(
        res => res.json()
      ).then(
        res => {
          
          setErrorList(res)
          setLoading(true)
        }
      )
    // waits until the request completes...

  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}

      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Hatalar</span>
          <span className='text-muted fw-semibold fs-7'>Son 5 hata</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}
          {
            (loading && errorList.length > 0 ) &&
            errorList.map((error: any,index) =>


              <div className='timeline-item' key={index}>
                {/* begin::Label */}
                <div className='timeline-label fw-bold text-gray-800 fs-8'>{error["sendTime"].replace("T"," ")}</div>
                {/* end::Label */}
                {/* begin::Badge */}
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-danger fs-1'></i>
                </div>
                {/* end::Badge */}
                {/* begin::Text */}
                <div className='fw-mormal timeline-content ps-5'>
                {error["message"]}
                </div>
                {/* end::Text */}
              </div>
            )}  
          
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export { Blackbox5Error }
