//production
export const BASE_URL = "https://bbxapi.elfaiot.com";
export const JSB_PORT = "/gate";
export const SCK_PORT = ""

//dev
//export const BASE_URL = "http://192.168.1.95";
//export const JSB_PORT = ":8443";
//export const SCK_PORT = ":8442";


//export const BASE_URL = "https://testbox.elfaiot.com";

//export const BASE_URL = "http://185.86.80.115";

export const AUTH_SERVICE = "/auth-service"
export const BLACKBOX_SERVICE = "/blackbox-service"
export const EMAIL_SERVICE = "/email-service"
export const SOCKET_SERVICE = "/socketService"
export const MAINTENANCE_SERVICE = "/maintenance-service"

export const GET_USER = BASE_URL+JSB_PORT+BLACKBOX_SERVICE+"/api/auth/loginauthservice"
export const VALIDATE_TOKEN = BASE_URL+JSB_PORT+BLACKBOX_SERVICE+"/api/auth/validate"


//                            System urls
export const SYSTEM_GET_ALL_ROLES = BASE_URL+JSB_PORT+BLACKBOX_SERVICE+"/api/auth/getroles/"
export const SYSTEM_DELETE_BY_ID_ROLES = BASE_URL+JSB_PORT+BLACKBOX_SERVICE+"/api/auth/deleterole/"
export const SYSTEM_ADD_ROLES = BASE_URL+JSB_PORT+BLACKBOX_SERVICE+"/api/auth/addrole/"


//                             User Urls
export const USER_URL = BASE_URL + JSB_PORT + AUTH_SERVICE + "/auth";
export const USER_ADD_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/auth/register";
export const USER_LIST_URL = USER_URL + "/list";
export const USER_DISTRIBITOR_URL = USER_URL + "/checkdistribitorid";
export const USER_RESET_PASSWORD = USER_URL + "/resetpassword";




//                             Blackbox Urls
export const BLACKBOXES_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/blackbox";
export const BLACKBOXES_LIST_URL = BLACKBOXES_URL + "/list";
export const BLACKBOXES_LIST_PAGEABLE_URL = BLACKBOXES_URL + "/getpageable";
export const BLACKBOXES_ACTIVE_LIST_PAGEABLE_URL = BLACKBOXES_URL + "/getisactiveblackbox";
export const BLACKBOXES_LIST_WITH_KEYWORD = BLACKBOXES_URL + "/getbykeywordcompany";
export const BLACKBOXES_LIST_WITH_KEYWORD_FOR_DIST = BLACKBOXES_URL + "/getbykeywordfordist";
export const BLACKBOXES_LIST_WITH_KEYWORD_FOR_COUNTRY = BLACKBOXES_URL + "/getbycountryforcmp";
export const BLACKBOXES_LIST_WITH_KEYWORD_FOR_COUNTRY_BY_DIST = BLACKBOXES_URL + "/getbycountryfordist";
export const BLACKBOXES_ADD_BY_DISTRIBUTOR_URL = BLACKBOXES_URL + "/addbydistributor";
export const BLACKBOXES_ADD_BY_COMPANY_URL = BLACKBOXES_URL + "/addbycompany";
export const BLACKBOXES_ADD_ELFA_URL = BLACKBOXES_URL + "/addelfatek";
export const BLACKBOXES_ONE_URL = BLACKBOXES_URL + "/getone";
export const BLACKBOXES_ONE_WITH_ID_URL = BLACKBOXES_URL + "/getwithid";
export const BLACKBOXES_ONE_WITH_COMPANY_ID_URL = BLACKBOXES_URL + "/getbyid";
export const BLACKBOXES_UPDATE_URL = BLACKBOXES_URL + "/update";
export const BLACKBOXES_UPDATE_BY_ELFATEK_URL = BLACKBOXES_URL + "/updatebyelfatek";
export const BLACKBOXES_UPDATE_BY_COMPANY_URL = BLACKBOXES_URL + "/updatebycompany";
export const BLACKBOXES_DELETE_BY_COMPANY_URL = BLACKBOXES_URL + "/deletebycompany";
export const BLACKBOXES_DELETE_BY_DISTRIBUTOR_URL = BLACKBOXES_URL + "/deletebydistributor";
export const BLACKBOXES_DELETE_BY_ELFATEK_URL = BLACKBOXES_URL + "/deleteelfatek";
export const BLACKBOXES_GET_BY_COMPANY_ID_URL = BLACKBOXES_URL + "/getlastone";
export const BLACKBOXES_GET_LIST_SIZE_URL = BLACKBOXES_URL + "/blackboxlistlength";
export const BLACKBOXES_GET_LIST_BY_COMPANYID = BLACKBOXES_URL + "/listbycompanyid";
export const BLACKBOXES_GET_LIST_BY_DISTRIBUTORID = BLACKBOXES_URL + "/listbydistributorid";
export const BLACKBOXES_GET_IS_UPDATE_REQUIRED= BLACKBOXES_URL + "/isupdaterequired";


//                             Blackbox Wifi Details
export const BLACKBOXES_DETAILS_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/graph/wifi";
export const BLACKBOXES_WIFI_URL = BLACKBOXES_DETAILS_URL + "/getlastone";


//                             Blackbox System Details
export const BLACKBOXES_SYSTEM_DETAILS_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/graph/system";
export const BLACKBOXES_SYSTEM_URL = BLACKBOXES_SYSTEM_DETAILS_URL + "/last";
export const BLACKBOXES_LAST5_URL = BLACKBOXES_SYSTEM_DETAILS_URL + "/last5dayList";
export const BLACKBOXES_MAX_CPU_URL = BLACKBOXES_SYSTEM_DETAILS_URL + "/maxcpu";
export const BLACKBOXES_AVG_TEMP_URL = BLACKBOXES_SYSTEM_DETAILS_URL + "/last5daytemp";
export const BLACKBOXES_MAX_TEMP_URL = BLACKBOXES_SYSTEM_DETAILS_URL + "/last5daymaxtemp";

//                             Blackbox Device Details
export const BLACKBOXES_DEVICE_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/device";
export const BLACKBOXES_DEVICE_ADD_URL = BLACKBOXES_DEVICE_URL + "/add";
export const BLACKBOXES_GENERIC_DEVICE_ADD_URL = BLACKBOXES_DEVICE_URL + "/genericadd";
export const BLACKBOXES_DEVICE_LIST_URL = BLACKBOXES_DEVICE_URL + "/list";
export const BLACKBOXES_DEVICE_DELETE_URL = BLACKBOXES_DEVICE_URL + "/delete";
export const BLACKBOXES_DEVICE_UPDATE_URL = BLACKBOXES_DEVICE_URL + "/updateonedevice"
export const BLACKBOXES_GET_DEVICE_LIST_SIZE_URL = BLACKBOXES_DEVICE_URL + "/blackboxdevicelistlength";
export const BLACKBOXES_GET_INDICATOR_GROUP_BY_COMPANYID = BLACKBOXES_DEVICE_URL + "/getindicatorgroupbycompanyid";
export const BLACKBOXES_GET_INDICATOR_GROUP_BY_DISTRIBUTORID = BLACKBOXES_DEVICE_URL + "/getindicatorgroupbydistributorid";
export const BLACKBOXES_GET_CANIOLABEL = BLACKBOXES_DEVICE_URL + "/getcaniolabel";
export const BLACKBOXES_UPDATE_CANIOLABEL = BLACKBOXES_DEVICE_URL + "/updatecaniolabel";
export const BLACKBOXES_GET_DEVICELIST_WITH_PAGEABLE = BLACKBOXES_DEVICE_URL + "/getdevicelistpageable";
export const BLACKBOXES_GET_DEVICE_WITH_UID = BLACKBOXES_DEVICE_URL + "/getonewithuid";
export const BLACKBOXES_GET_CANIO_WITH_BLACKBOXID = BLACKBOXES_DEVICE_URL + "/getcaniobyblackboxıd";
//                              Blackbox Error Details
export const BLACKBOXES_ERROR_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/error";
export const BLACKBOXES_GET_ERROR_URL = BLACKBOXES_ERROR_URL + "/getall";
export const BLACKBOXES_GET_5_ERROR_URL = BLACKBOXES_ERROR_URL + "/getbybblast5";
export const BLACKBOXES_GET_PAGEABLE_ERROR_URL = BLACKBOXES_ERROR_URL + "/getallpage";
export const BLACKBOXES_GET_LAST_5_ERROR = BLACKBOXES_ERROR_URL + "/getLastError";
//                              Blackbox Rs485 Details
export const BLACKBOXES_RS485_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/rs485";
export const BLACKBOXES_GET_LIST_RS485_URL = BLACKBOXES_RS485_URL + "/list";
export const BLACKBOXES_ADD_RS485_URL = BLACKBOXES_RS485_URL + "/add";
export const BLACKBOXES_DELETE_RS485_URL = BLACKBOXES_RS485_URL + "/delete";
export const BLACKBOXES_GET_LIST_PAGEABLE_RS485_URL = BLACKBOXES_RS485_URL + "/getPageable";
export const BLACKBOXES_CHANGE_STATUS__RS485_URL = BLACKBOXES_RS485_URL + "/changestatus";
export const BLACKBOXES_GET_STATUS__RS485_URL = BLACKBOXES_RS485_URL + "/getallbyStatus";
export const BLACKBOXES_RS485_LIST_SIZE_URL = BLACKBOXES_RS485_URL + "/getcommandsize";

//                              Blackbox Driver Command
export const BLACKBOXES_DRIVERCOMMAND_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/drivercommand";
export const BLACKBOXES_DRIVERCOMMAND_GETALL_URL = BLACKBOXES_DRIVERCOMMAND_URL + "/getall";
export const BLACKBOXES_DRIVERCOMMAND_ADD_URL = BLACKBOXES_DRIVERCOMMAND_URL + "/add";
export const BLACKBOXES_DRIVERCOMMAND_DELETE_URL = BLACKBOXES_DRIVERCOMMAND_URL + "/delete";
export const BLACKBOXES_DRIVERCOMMAND_GETBYBLACKBOX_URL = BLACKBOXES_DRIVERCOMMAND_URL + "/getbyblackbox";


//                              Blackbox Driver
export const BLACKBOXES_DRIVER_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/driver";
export const BLACKBOXES_DRIVER_GET_URL = BLACKBOXES_DRIVER_URL + "/getall";


//                              Blackbox Graphic
export const BLACKBOXES_BLACKBOX_GRAPHIC_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/blackboxgraphic";
export const BLACKBOXES_BLACKBOX_GRAPHIC_GET_URL = BLACKBOXES_BLACKBOX_GRAPHIC_URL + "/getgraphic";
export const BLACKBOXES_BLACKBOX_ANALYSIS_GRAPHIC_GET_URL = BLACKBOXES_BLACKBOX_GRAPHIC_URL + "/getanalysisgraphicdatetime";
export const BLACKBOXES_BLACKBOX_GRAPHIC_GET_DATETIME_URL = BLACKBOXES_BLACKBOX_GRAPHIC_URL + "/getgraphicdatetime";
export const BLACKBOXES_BLACKBOX_GRAPHIC_GET_RS_URL = BLACKBOXES_BLACKBOX_GRAPHIC_URL + "/getcommands";
export const BLACKBOXES_BLACKBOX_GRAPHIC_GET_MONTLY_WEIGHT_URL = BLACKBOXES_BLACKBOX_GRAPHIC_URL + "/montlyweight";
export const BLACKBOXES_BLACKBOX_GRAPHIC_GET_WEIGHT_OF_INDICATOR_URL = BLACKBOXES_BLACKBOX_GRAPHIC_URL + "/getweightofindicator";
export const BLACKBOXES_BLACKBOX_GRAPHIC_GET_WEIGHT_OF_INDICATOR_DATETIME_URL = BLACKBOXES_BLACKBOX_GRAPHIC_URL + "/getweightofindicatordatetime";
//                              Blackbox Table Grahic
export const BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/tablegraphic";
export const BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_GET_URL = BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_URL + "/get";
export const BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_GET_PAGEABLE_URL = BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_URL + "/getpageable";
export const BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_GET_TOTAL_CAR_TIME = BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_URL + "/gettotalcartime";
export const BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_GET_TOTAL_BRIDGE_TIME = BLACKBOXES_BLACKBOX_TABLE_GRAPHIC_URL + "/gettotalbridgetime";

//                              Company 
export const COMPANY_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/company";
export const COMPANY_LIST_URL = COMPANY_URL + "/listcompany";
export const COMPANY_LIST_URL_FOR_DIST = COMPANY_URL + "/listcompanyfordist";
export const DISTRIBUTOR_LIST_URL = COMPANY_URL + "/listdistributor";
export const GET_ONE_COMPANY = COMPANY_URL + "/getonecompany";
export const UPDATE_ONE_USER_BY_ELFATEK = COMPANY_URL + "/updateoneuserbyelfatek"

//                              Blackbox Message
export const BLACKBOX_MESSAGE_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/message";
export const BLACKBOX_MESSAGE_LIST_URL = BLACKBOX_MESSAGE_URL + "/list";
export const BLACKBOX_DEVICE_CURRENT_SITUATION_URL = BLACKBOX_MESSAGE_URL + "/devicecurrentsituation";


//                              Blackbox Heartbit
export const BLACKBOX_BLACKBOX_HEARTBIT_URL = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/heartbit";
export const BLACKBOX_BLACKBOX_ONE_BLACKBOX_HEARTBIT_URL = BLACKBOX_BLACKBOX_HEARTBIT_URL + "/lastone";
export const BLACKBOX_BLACKBOX_ALL_HEARTBIT_URL = BLACKBOX_BLACKBOX_HEARTBIT_URL + "/pageable";


//                              Blackbox Media
export const MEDIA = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/media";
export const MEDIA_USER_IMAGE = MEDIA  + "/uploaduser";

//                              Coordinate Media
export const COORDINATE = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/coordinate";
export const COORDINATE_CAC_DEVICE_LIST = COORDINATE  + "/devicelist";
export const GET_COORDINATE_MODEL = COORDINATE  + "/getmodel";
export const ADD_COORDINATE_MODEL = COORDINATE  + "/addmodel";
export const GET_CRANE_COORDINATE = COORDINATE  + "/getcoordinate";

//                              Socket 
export const SOCKET = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/socket";
export const SOCKET_GET_USED_BLACKBOX = SOCKET  + "/getusedblackbox";
export const SOCKET_GET_BLACKBOX_SOCKET_STATUS = SOCKET  + "/blackboxsocketstatus";


//                      predictivemaintenance
export const PREDICTIVEMAINTENANCE = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/predictivemaintenance";
export const ADD_PREDICTIVEMAINTENANCE = PREDICTIVEMAINTENANCE  + "/add";
export const DELETE_BY_ID_PREDICTIVEMAINTENANCE = PREDICTIVEMAINTENANCE  + "/deletebyid";
export const DELETE_BY_UIDPREDICTIVEMAINTENANCE = PREDICTIVEMAINTENANCE  + "/deletebyuid";
export const LIST_PREDICTIVEMAINTENANCE = PREDICTIVEMAINTENANCE  + "/listbyuid";
export const LIST_PREDICTIVEMAINTENANCE_BY_UID_AND_CONTAINING_DESCRIPTION = PREDICTIVEMAINTENANCE  + "/listbyuidandcontaining";
export const GET_INDICATOR_WEIGHT_FOR_PREDICTIVEMAINTANCE = PREDICTIVEMAINTENANCE  + "/indicatorWeight";
export const GET_INDICATOR_WEIGHT_BY_BLACKBOXID = PREDICTIVEMAINTENANCE  + "/indicatorweightbyblackboxid";
export const GET_INDICATOR_ROPE_RATE = PREDICTIVEMAINTENANCE  + "/getroperate";
//                      System event Logs
export const SYSTEM_EVENT_LOGS = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/eventlog";
export const SYSTEM_EVENT_LOGS_LIST_BY_BLACKBOXID = SYSTEM_EVENT_LOGS + "/list";


//                      Canio output
export const CANIO_OUTPUT = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/canioutput";
export const CANIO_OUTPUT_GET_BY_UID = CANIO_OUTPUT + "/getbyuid";
export const CANIO_OUTPUT_SAVE = CANIO_OUTPUT + "/save";

//                      Blackbox Excel Data output
export const BLACKBOX_EXCEL_DATA = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/excel";
export const BLACKBOX_EXCEL_DATA_PREPARE_REQUEST = BLACKBOX_EXCEL_DATA + "/preparexcel";
export const BLACKBOX_EXCEL_DATA_GET_EXCEL_STATUS = BLACKBOX_EXCEL_DATA + "/getexcelstatus";
export const BLACKBOX_DEVICE_EXCEL_PATH = BLACKBOX_EXCEL_DATA + "/download";

//                      Product
export const PRODUCT = BASE_URL + JSB_PORT + BLACKBOX_SERVICE + "/api/product";
export const ADD_PRODUCT_BY_USERID = PRODUCT + "/";
export const GET_PAGEABLE_PRODUCT_BY_USERID = PRODUCT + "/pageable";
export const GET_PRODUCT_LIST_BY_BRAND_TEXT = PRODUCT + "/filtertext";





////////////////////////SOCKET_SERVICE
export const SOCKET_SERVICE_COMMAND_URL = BASE_URL + JSB_PORT + SOCKET_SERVICE + "/api/command";
export const SEND_COMMAND = SOCKET_SERVICE_COMMAND_URL + "/send"


////////////////////////MAINTENANCE-SERVICE
///PRODUCT
export const MAINTENANCE_SERVICE_COMMAND_URL = BASE_URL + JSB_PORT + MAINTENANCE_SERVICE + "/api/product";
export const MAINTENANCE_ADD_PRODUCT = MAINTENANCE_SERVICE_COMMAND_URL + "/add"
export const MAINTENANCE_GET_PRODUCT = MAINTENANCE_SERVICE_COMMAND_URL + "/list"
export const CRANE_MAINTENANCE_DONE_URL = MAINTENANCE_SERVICE_COMMAND_URL + "/maintenancedone"

///CRANE OVERVIEW 
export const MAINTENANCE_SERVICE_CRANE_OVERVIEW_URL = BASE_URL + JSB_PORT + MAINTENANCE_SERVICE + "/api/craneoverview";
export const CRANE_OVERVIEW_UPDATE_URL = MAINTENANCE_SERVICE_CRANE_OVERVIEW_URL + "/updatecranedata"
export const CRANE_OVERVIEW_GET_WEIGHT_TABLE_DATA_URL = MAINTENANCE_SERVICE_CRANE_OVERVIEW_URL + "/getweighttable"
export const CRANE_OVERVIEW_GET_X_ANGLE_LIM_TABLE_DATA_URL = MAINTENANCE_SERVICE_CRANE_OVERVIEW_URL + "/getxanglelimrate"
export const CRANE_OVERVIEW_GET_Y_ANGLE_LIM_TABLE_DATA_URL = MAINTENANCE_SERVICE_CRANE_OVERVIEW_URL + "/getyanglelimrate"

//MAINTENANCE DEVICE
export const MAINTENANCE_SERVICE_CRANE_DEVICE_URL = BASE_URL + JSB_PORT + MAINTENANCE_SERVICE + "/v1/api/device";
export const CRANE_ADD_DEVICE_URL = MAINTENANCE_SERVICE_CRANE_DEVICE_URL + "/"
export const CRANE_GET_ONE_DEVICE_URL = MAINTENANCE_SERVICE_CRANE_OVERVIEW_URL + "/"

//MAINTENANCE BLACKBOX
export const MAINTENANCE_SERVICE_CRANE_BLACKBOX_URL = BASE_URL + JSB_PORT + MAINTENANCE_SERVICE + "/v1/api/blackboxmaintenance";
export const CRANE_ADD_BLACKBOX_URL = MAINTENANCE_SERVICE_CRANE_BLACKBOX_URL + "/add"
export const CRANE_GET_BLACKBOX_URL = MAINTENANCE_SERVICE_CRANE_BLACKBOX_URL + "/getblackboxmaintenance"


//MAINTENANCE OPERATIONS
export const MAINTENANCE_SERVICE_CRANE_OPERATIONS_URL = BASE_URL + JSB_PORT + MAINTENANCE_SERVICE + "/v1/api/maintenanceoperations";
export const GET_OPERATIONS_BY_BLACKBOX_URL = MAINTENANCE_SERVICE_CRANE_OPERATIONS_URL + "/getbyblackbox"
export const GET_OPERATIONS_BY_PRODUCT_URL = MAINTENANCE_SERVICE_CRANE_OPERATIONS_URL + "/getbyproduct"