import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { USER_URL,GET_USER,VALIDATE_TOKEN } from '../../../../urls';

const API_URL = USER_URL
//const API_URL = "https://bbxapi.elfaiot.com:8443/api/auth"
//const API_URL = "http://192.168.1.95:8443/api/auth"
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/validate`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
//export const GET_USER = `${API_URL}/getuser`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const GETUSERFROMBLACKBOX = GET_USER
// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
}
export async function loginWithout(username: string, password: string): Promise<AuthModel> {
  const response = await fetch(LOGIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const authlogin: AuthModel = await response.json();
  return authlogin;
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}



//////////////////////////
export function getUser(token: string, username:string) {
  return axios.post<UserModel>(GETUSERFROMBLACKBOX, {
   
    token: token,
    username: username,
  })
}

export async function getUserWithout(token:string,username:string): Promise<UserModel>{

  
  const response = await fetch(GETUSERFROMBLACKBOX , {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({
      username: username,
      
    }),
    
  })
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const userData: UserModel = await response.json();
  return userData;
}   

///////////////////////
export function getUserByToken(token: string,) {

  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: token,
      token: token,
    },
   

    
  })
}

export async function getUserByTokenWithout(token:string):Promise<UserModel> {


  const response = await fetch(GET_USER_BY_ACCESSTOKEN_URL , {
    method: "GET",
    headers: {
      Authorization: token,
    },
  })

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const userData: UserModel = await response.json();
  return userData;
}   

export async function validateToken(token:string):Promise<UserModel> {
 
  const response = await fetch(VALIDATE_TOKEN , {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const userData: UserModel = await response.json();
  console.log(userData)
  return userData;
}  