/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext';
import { useAuth } from '../auth';
import { SYSTEM_GET_ALL_ROLES } from '../../../urls';
import { toAbsoluteUrl } from '../../../_elfaiotbbx/helpers';
import { AsideMenuItem } from '../../../_elfaiotbbx/layout/components/aside/AsideMenuItem';



const Roles: React.FC = () => {
  const currentBlackBoxId = useContext(blackboxIdContext);
  const [errorList, setErrorList] = useState([])
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()

  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState()

  const token = currentUser?.token

  const createHeader = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', "" + token?.toString());
    return headers
  }
  useEffect(() => {

    getErrorPageable()


    window.setTimeout(function () { setLoading(true) }, 2000);




  }, [loading, pageNumber]);


  async function getErrorPageable() {
    const response = await fetch(SYSTEM_GET_ALL_ROLES + `${pageNumber}/10`, {
      method: "GET",
      headers: createHeader()
    })
      .then(
        res => res.json()
      ).then(
        res => {
          console.log(res)
          setPageNumber(res["pageable"]["pageNumber"])
          setPageSize(res["pageable"]["pageSize"])
          setTotalElements(res["totalElements"])
          setTotalPages(res["totalPages"])
          setErrorList(res["content"])
        }
      )


  }

  const pageReduce = () => {

    if (pageNumber >= 1 && pageNumber <= totalPages) {

      setPageNumber(pageNumber - 1)


    }
  }

  const pageIncrease = () => {

    if (pageNumber >= 0 && pageNumber < totalPages) {
      setPageNumber(pageNumber + 1)

    }
  }
  const pageChanger = () => {

    if (pageNumber >= 0) {

    }
  }

  function isNumber(char) {
    return /^\d$/.test(char);
  }

  const setCanLabel = (label: string) => {
    const labelSize = label.length
    console.log(label.replace("x", "x0"))
    if (labelSize <= 3 && isNumber(label[2])) {
      console.log(label.replace("x", "x0"))
      return label.replace("x", "x0")
    }
    return label

  }

  return (
    (!Array.isArray(errorList) == false) ?
      <div>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Uyarılar</span>
            <span className='text-muted mt-1 fw-semibold fs-7'> Yan ürünler ve cihaz üzerinde gerçekleşen hatalar</span>
          </h3>

        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-300px rounded-start'>Node Id</th>
                  <th className='min-w-125px'>Durum</th>
                  <th className='min-w-125px'></th>
                  <th className='min-w-200px'></th>
                  <th className='min-w-150px'>Hata Tarihi</th>
                  <th className='min-w-200px text-end rounded-end'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {
                  (errorList.length != 0) &&
                  errorList.map((error: any) => {

                    return (
                      <tr key={error.id}>
                        <td >
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <span className='symbol-label bg-light'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
                                  className='h-75 align-self-end'
                                  alt=''
                                />
                              </span>
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                {setCanLabel(error["nodeId"])}
                              </a>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {error["can"]}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {error["message"]}
                          </a>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {/* {error.serialNumber} */}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'></span>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {/* {error.description} */}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>

                          </span>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {error.sendTime.replace("T", " ")}
                            {/* {blackbox.createdTime.replace("T"," ")} */}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>

                          </span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                          >
                            Sil
                          </a>

                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        <div className='d-flex flex-stack flex-wrap pt-10'>
          <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of {totalElements} entries</div>

          <ul className='pagination'>
            <li className='page-item previous'>
              <a className='page-link' onClick={pageReduce}>
                <i className='previous'></i>
              </a>
            </li>

            <li className='page-item active'>
              <a href='#' className='page-link'>
                {pageNumber + 1}
              </a>
            </li>

            <li className='page-item next'>
              <a className='page-link' onClick={pageIncrease}>
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>

      </div>

      :
      <div >
        <h2>Kayıtlı Karakutu yok</h2>
        <AsideMenuItem to='/crafted/pages/wizards/AddBlackbox' title='Ekle' hasBullet={true} />
      </div>
  )
}

export { Roles }
