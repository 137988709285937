
import React, { useContext, useEffect, useState } from 'react'
import { useDebounce } from '../../../_elfaiotbbx/helpers'
import { LIST_PREDICTIVEMAINTENANCE, LIST_PREDICTIVEMAINTENANCE_BY_UID_AND_CONTAINING_DESCRIPTION } from '../../../urls'
import { blackboxIdContext } from '../profile/components/contexts/BlackboxIdContext'
import { useAuth } from '../auth'
import './Reparationstyle.css'

type Props = {
    className: string
    uid:any
    deviceDescription: any
}

const PercentTable: React.FC<Props> = ({ className, uid, deviceDescription }) => {

    const [tableInitialize, setTableInitialize] = useState(false)
    const currentBlackBoxId = useContext(blackboxIdContext);
    const { currentUser, logout } = useAuth()
    const token = currentUser?.token
    const [tableOfReparation, setTableOfReparation] = useState([])
    const [pageInitialize, setPageInitialize] = useState(false)
   
    const createHeader = () => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', "" + token?.toString());
        return headers
      }

    async function getReparationList() {
        
        const response = await fetch(LIST_PREDICTIVEMAINTENANCE + `?uid=${uid}&startNumber=${0}&endNumber=${10}`, {
            method: "GET",
            headers: createHeader(),
        })
        if (response.status === 401) {
            logout();
            return;
        }
        const data = await response.json()
        setTableOfReparation(data)
        console.log(data)
        setTableInitialize(true)
    }

    useEffect(() => {
        if (pageInitialize == false) {
            getReparationList()
            setTimeout(() => { setPageInitialize(true) }, 1000)
        }

    }, [pageInitialize, tableInitialize])
    function twoNumbersRatio(allTimeHours) {
        
        if (allTimeHours > 100) return "100" + '%+'
        return allTimeHours.toFixed(4).toString() + '%';
    }
    function handleBarSize(allTimeHours) {


        if (allTimeHours > 100) return "100%";
        return allTimeHours.toFixed(4).toString() + "%";
    }
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        console.log(123)
        if (debouncedSearchTerm) {
            fetch(LIST_PREDICTIVEMAINTENANCE_BY_UID_AND_CONTAINING_DESCRIPTION + `?text=${debouncedSearchTerm}&uid=${uid}`, {
                method: "GET",
                headers: createHeader(),

            })
                .then(response => response.json())
                .then(response => console.log(response))
                .catch(error => console.error('Error fetching data:', error));
        } else {
          
        }
    }, [debouncedSearchTerm]);

   
    function getDeviceTypeLabelName(type:string){
        if(type === "1") return ""
        else if(type === "2") return " tetik"
        else if(type === "3") return " saat"
        else if(type === "4") return " kg"

    }
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        
    };

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <link rel="stylesheet" href="Reparationstyle.css"></link>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{deviceDescription}</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'></span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Aramayı daralt'
                >
                   
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-140px'>Açıklama</th>
                                <th className='min-w-150px'>Input</th>
                                <th className='min-w-140px'>Marka</th>
                                <th className='min-w-140px'>Model</th>
                                <th className='min-w-120px'>Tahmini kalan kullanım</th>
                                <th className='min-w-100px text-end'>Tahmini çalışma ömrü</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {Array.isArray(tableOfReparation["ioList"]) && (tableInitialize == true && tableOfReparation["ioList"].length != 0) &&
                                tableOfReparation["ioList"].map((row, index) => {
                                    return (
                                        <tr key={index}>


                                            <td>
                                                <a className='text-dark fw-bold text-hover-primary d-block fs-6 tooltiptext' style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {tableOfReparation["description"][index]}
                                                </a>

                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>

                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {tableOfReparation["ioList"][index]}
                                                        </a>

                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <a className='text-dark fw-bold text-hover-primary d-block fs-6 tooltiptext' style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {tableOfReparation["productList"][index]["brand"]}
                                                </a>

                                            </td>
                                            <td>
                                                <a className='text-dark fw-bold text-hover-primary d-block fs-6 tooltiptext' style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {tableOfReparation["productList"][index]["model"]}
                                                </a>

                                            </td>
                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-muted me-2 fs-7 fw-semibold'>{twoNumbersRatio(tableOfReparation["predictTime"][index])}</span>
                                                    </div>
                                                    <div className='progress h-6px w-100'>
                                                        <div
                                                            className='progress-bar bg-primary'
                                                            role='progressbar'
                                                            style={{ width: handleBarSize(tableOfReparation["predictTime"][index]) }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-end flex-shrink-0'>
                                                    {tableOfReparation["productList"][index]["maxUsageTime"] + getDeviceTypeLabelName(tableOfReparation["productList"][index]["deviceTimeType"])}
                                                </div>
                                            </td>
                                        </tr>

                                    )

                                })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
    )
}

export { PercentTable }
